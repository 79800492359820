import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import { fetchJob, updateJob } from 'api/jobsApi';
import { fetchProperties, fetchProperty } from 'api/propertiesApi';
import JobFormFields from './JobFormFields';
import styles from './JobForm.module.css';

export default function JobEdit() {
  const { jobId } = useParams();
  const [formData, setFormData] = useState(null);
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [cleaners, setCleaners] = useState([]);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const loadJobData = async () => {
      try {
        const job = await fetchJob(jobId);
        setFormData({
          ...job,
          price: (job.price_cents / 100).toString(),
        });
        const property = await fetchProperty(job.property_id);
        setSelectedProperty(property);
        setCleaners(property.cleaners || []);
      } catch (error) {
        console.error('Error loading job data:', error);
      }
    };

    const loadProperties = async () => {
      try {
        const properties = await fetchProperties();
        setProperties(properties);
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };

    loadJobData();
    loadProperties();
  }, [jobId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePropertySelect = async (e) => {
    const propertyId = e.target.value;
    try {
      const property = await fetchProperty(propertyId);
      setSelectedProperty(property);
      setCleaners(property.cleaners || []);
      setFormData(prevState => ({
        ...prevState,
        property_id: propertyId,
        price: property && property.default_job_price_cents != null 
          ? (property.default_job_price_cents / 100).toString() 
          : '',
        price_currency: property?.default_job_price_currency || '',
        cleaning_from: property?.default_cleaning_from || '',
        cleaning_until: property?.default_cleaning_until || '',
        description: property?.description || ''
      }));
    } catch (error) {
      console.error('Error fetching property:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Destructure the fields to exclude them from the jobData
      const { created_at, updated_at, id, paid, ...filteredFormData } = formData;

      const jobData = {
        ...filteredFormData,
        price_cents: Math.round(parseFloat(formData.price) * 100),
        user_id: user.id,
      };

      await updateJob(jobId, jobData);
      navigate('/cleaning/calendar');
    } catch (error) {
      console.error('Error updating job:', error);
    }
  };

  if (!formData) return <div>Loading...</div>;

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.formTitle}>Edit Job</h2>
      <JobFormFields
        formData={formData}
        setFormData={setFormData}
        properties={properties}
        cleaners={cleaners}
        selectedProperty={selectedProperty}
        handleInputChange={handleInputChange}
        handlePropertySelect={handlePropertySelect}
        handleSubmit={handleSubmit}
        user={user}
        formMode="edit"
      />
    </div>
  );
}
