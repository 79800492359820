import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './NewPayout.module.css';
import { fetchCleaners } from 'api/usersApi';
import { fetchJobs } from 'api/jobsApi';
import RatingBonusModal from './RatingBonusModal';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { createPayoutRule, updatePayoutRule, fetchPayoutRules, deletePayoutRule, previewPayoutRule } from 'api/payoutRuleConfigurationsApi';
import { createPayout } from 'api/payoutsApi';
import Select from 'react-select';

const formatCurrency = (amount, currency = 'EUR') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  });
  return formatter.format(amount);
};

export default function NewPayout() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [jobs, setJobs] = useState([]); // All available jobs
  const [filteredJobs, setFilteredJobs] = useState([]); // Jobs after filtering
  const [cleaners, setCleaners] = useState([]); // All cleaners
  const [formData, setFormData] = useState({
    selectedJobs: [],
    dateRange: {
      from: '',
      until: ''
    },
    selectedCleaner: '',
    jobStatus: 'completed', // Default to completed jobs
    rules: {
      ratingBonus: false,
      managementFee: 0,
      customAdjustments: []
    },
    paymentMethod: 'bank_transfer',
    additionalPayouts: [], // Array to store multiple payouts
    ratingBonus: {
      actualRating: '',
      thresholdRating: '4.8',
      bonusPerPoint: '',
      calculatedBonus: 0
    },
    bonusConfig: null,
    selectedProperties: [] // Add this new field
  });
  const [showRatingModal, setShowRatingModal] = useState(false);

  // Fetch cleaners when component mounts
  useEffect(() => {
    const loadCleaners = async () => {
      try {
        const cleaners = await fetchCleaners();
        setCleaners(cleaners);
        console.log(cleaners);
      } catch (error) {
        console.error('Error fetching cleaners:', error);
      }
    };
    loadCleaners();
  }, []);

  // Fetch jobs when component mounts
  useEffect(() => {
    const loadJobs = async () => {
      try {
        const response = await fetchJobs();
        // Log the response to see its structure
        console.log('API Response:', response);
        
        // Set jobs only if response is valid
        if (response && response.jobs) {
          setJobs(response.jobs);
        } else if (Array.isArray(response)) {
          setJobs(response);
        } else {
          console.warn('Unexpected jobs data structure:', response);
          setJobs([]);
        }
      } catch (error) {
        console.error('Error loading jobs:', error);
        setJobs([]);
      }
    };
    loadJobs();
  }, []);

  // Filter jobs based on current criteria
  const filterJobs = (allJobs, cleaner, status, dateRange) => {
    // If no cleaner is selected, return empty array
    if (!cleaner) {
      setFilteredJobs([]);
      return;
    }

    let filtered = [...allJobs];

    // Filter by cleaner
    filtered = filtered.filter(job => job?.cleaner?.id === parseInt(cleaner));

    // Filter by properties if any are selected
    if (formData.selectedProperties && formData.selectedProperties.length > 0) {
      filtered = filtered.filter(job => 
        formData.selectedProperties.includes(job.property?.id.toString())
      );
    }

    // Filter by status if specified
    if (status && status !== 'all') {
      filtered = filtered.filter(job => job.status === status);
    }

    // Filter by date range if specified
    if (dateRange?.from && dateRange?.until) {
      filtered = filtered.filter(job => {
        const jobDate = new Date(job.date_of_job);
        const fromDate = new Date(dateRange.from);
        const untilDate = new Date(dateRange.until);
        return jobDate >= fromDate && jobDate <= untilDate;
      });
    }

    // Filter out paid jobs (moved outside of date range condition)
    filtered = filtered.filter(job => job.paid !== true);

    setFilteredJobs(filtered);
  };

  // Update filters and re-filter jobs
  useEffect(() => {
    filterJobs(jobs, formData.selectedCleaner, formData.jobStatus, formData.dateRange);
  }, [
    jobs, 
    formData.selectedCleaner, 
    formData.jobStatus, 
    formData.dateRange,
    formData.selectedProperties
  ]);

  const handleJobToggle = (jobId) => {
    setFormData(prev => {
      const job = filteredJobs.find(j => j.id === jobId);
      const selectedJobs = prev.selectedJobs.includes(jobId)
        ? prev.selectedJobs.filter(id => id !== jobId)
        : [...prev.selectedJobs, jobId];

      // Check for currency conflicts
      const selectedJobsData = filteredJobs.filter(j => selectedJobs.includes(j.id));
      const selectedCurrencies = new Set(selectedJobsData.map(j => j.price_currency));

      if (selectedCurrencies.size > 1) {
        alert('Cannot select jobs with different currencies.');
        return prev; // Return previous state without changes
      }

      return { ...prev, selectedJobs };
    });
  };

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      dateRange: {
        ...prev.dateRange,
        [name]: value
      }
    }));
  };

  const handleCleanerChange = (e) => {
    const newCleaner = e.target.value;
    setFormData(prev => ({
      ...prev,
      selectedCleaner: newCleaner
    }));
  };

  const handleJobStatusChange = (e) => {
    setFormData(prev => ({
      ...prev,
      jobStatus: e.target.value
    }));
  };

  const handleNext = () => {
    // Ensure all selected jobs have the same currency
    const selectedJobsData = filteredJobs.filter(job => formData.selectedJobs.includes(job.id));
    const selectedCurrencies = new Set(selectedJobsData.map(job => job.price_currency));

    if (selectedCurrencies.size > 1) {
      alert('Cannot proceed to the next step with jobs in different currencies.');
      return;
    }

    setStep(prev => prev + 1);
  };

  const handleBack = () => {
    setStep(prev => prev - 1);
  };

  const calculateTotalAmount = (selectedJobs, filteredJobs, additionalPayouts) => {
    const jobsTotal = filteredJobs
      .filter(job => selectedJobs.includes(job.id))
      .reduce((sum, job) => sum + job.price_cents, 0);
    
    const additionalPayoutsTotal = additionalPayouts
      .reduce((sum, payout) => sum + (Number(payout.amount) * 100), 0); // Convert euros to cents

    return jobsTotal + additionalPayoutsTotal;
  };

  const handleCreatePayout = async () => {
    try {
      const jobsData = filteredJobs
        .filter(job => formData.selectedJobs.includes(job.id))
        .map(job => ({
          id: job.id,
          amount: job.price_cents 
        }));

      const totalAmountCents = calculateTotalAmount(
        formData.selectedJobs,
        filteredJobs,
        formData.additionalPayouts
      );

      const payoutData = {
        payout: {
          date: new Date().toISOString().split('T')[0],
          cleaner_id: parseInt(formData.selectedCleaner),
          total_amount_cents: totalAmountCents,
          total_amount_currency: getSelectedJobsCurrency()
        },
        jobs: jobsData
      };

      await createPayout(payoutData);
      navigate('/cleaning/dashboard');
    } catch (error) {
      console.error('Error creating payout:', error);
    }
  };

  // Update the submit handler to use the new createPayout function
  const handleSubmit = (e) => {
    e.preventDefault();
    handleCreatePayout();
  };

  // Update jobsWithCalculatedData to include currency
  const jobsWithCalculatedData = filteredJobs.map(job => {
    const formattedDate = new Date(job.date_of_job).toLocaleDateString();
    const formattedAmount = formatCurrency(job.price_cents / 100, job.price_currency);
    const assignedCleaner = job.cleaner;
    const isSelected = formData.selectedJobs.includes(job.id);

    return {
      ...job,
      formattedDate,
      formattedAmount,
      assignedCleaner,
      isSelected
    };
  });

  const handleAdditionalPayoutTypeChange = (e) => {
    const type = e.target.value;
    if (!type) return;

    if (type === 'rating_bonus') {
      setShowRatingModal(true);
      e.target.value = '';
    } else {
      setFormData(prev => ({
        ...prev,
        additionalPayouts: [...prev.additionalPayouts, {
          id: `temp_${Date.now()}`,
          type,
          amount: '',
          description: type === 'other' ? '' : undefined
        }]
      }));
    }
  };

  const handlePayoutAmountChange = (id, amount) => {
    // Update local state immediately for responsive UI
    setFormData(prev => ({
        ...prev,
        additionalPayouts: prev.additionalPayouts.map(p =>
            p.id === id ? { ...p, amount } : p
        )
    }));
  };

  const handlePayoutDescriptionChange = (id, description) => {
    // Update local state immediately for responsive UI
    setFormData(prev => ({
        ...prev,
        additionalPayouts: prev.additionalPayouts.map(p =>
            p.id === id ? { ...p, description } : p
        )
    }));
  };

  const handlePayoutBlur = async (id) => {
    try {
        const payout = formData.additionalPayouts.find(p => p.id === id);
        if (!payout) return;

        const ruleData = {
            payout_rule_type_id: payout.type,
            cleaner_id: formData.selectedCleaner,
            configuration: {}
        };

        // Set configuration based on rule type
        switch(payout.type) {
            case 'rating_bonus':
                ruleData.configuration = {
                    actual_rating: payout.ratingConfig.actualRating,
                    threshold_rating: payout.ratingConfig.thresholdRating,
                    amount: payout.ratingConfig.bonusPerPoint
                };
                break;
            case 'management_fee':
                ruleData.configuration = { amount: payout.amount };
                break;
            case 'other':
                ruleData.configuration = {
                    amount: payout.amount,
                    description: payout.description
                };
                break;
            default:
                // Handle any unexpected payout types
                console.warn('Unexpected payout type:', payout.type);
                break;
        }

        // If the payout has a temporary ID (new rule), create it
        if (typeof id === 'string' && id.startsWith('temp_')) {
            const response = await createPayoutRule(ruleData);
            // Update local state with the real ID from the server
            setFormData(prev => ({
                ...prev,
                additionalPayouts: prev.additionalPayouts.map(p =>
                    p.id === id ? { ...p, id: response.id } : p
                )
            }));
        } else {
            // Otherwise update existing rule
            await updatePayoutRule(id, ruleData);
        }
    } catch (error) {
        console.error('Error saving payout rule:', error);
    }
  };

  const handleDeletePayout = async (id) => {
    try {
        // Call the API to delete the rule
        await deletePayoutRule(id);
        
        // Update local state after successful deletion
        setFormData(prev => ({
            ...prev,
            additionalPayouts: prev.additionalPayouts.filter(payout => payout.id !== id),
            // If it was a rating bonus, also clear the bonus config
            ...(prev.additionalPayouts.find(p => p.id === id)?.type === 'rating_bonus' && {
                bonusConfig: null
            })
        }));
    } catch (error) {
        console.error('Error deleting payout rule:', error);
        // You might want to show an error message to the user here
    }
  };

  const handleSaveBonus = async (config) => {
    try {
        const ruleData = {
            payout_rule_type_id: 'rating_bonus',
            cleaner_id: formData.selectedCleaner,
            configuration: {
                actual_rating: config.actualRating,
                threshold_rating: config.thresholdRating,
                amount: config.bonusPerPoint
            }
        };

        // Find if there's an existing rating bonus rule
        const existingRule = formData.additionalPayouts.find(p => p.type === 'rating_bonus');
        
        let response;
        if (existingRule) {
            response = await updatePayoutRule(existingRule.id, ruleData);
        } else {
            response = await createPayoutRule(ruleData);
        }

        // Get the calculated amount from the backend
        const preview = await previewPayoutRule(response.id);
        const calculatedAmount = preview.amount_cents / 100; // Convert cents to euros
        
        setFormData(prev => ({
            ...prev,
            bonusConfig: config,
            additionalPayouts: existingRule 
                ? prev.additionalPayouts.map(p => 
                    p.id === existingRule.id 
                        ? {
                            id: existingRule.id,
                            type: 'rating_bonus',
                            amount: calculatedAmount,
                            ratingConfig: config
                          }
                        : p
                  )
                : [
                    ...prev.additionalPayouts,
                    {
                        id: response.id,
                        type: 'rating_bonus',
                        amount: calculatedAmount,
                        ratingConfig: config
                    }
                ]
        }));
        setShowRatingModal(false);
    } catch (error) {
        console.error('Error saving bonus configuration:', error);
    }
  };

  const handleEditBonus = () => {
    setShowRatingModal(true);
  };

  const handleCheckboxClick = (e, jobId) => {
    e.stopPropagation(); // Stop event from bubbling up
    handleJobToggle(jobId);
  };

  const getAvailableTypes = () => {
    const usedTypes = new Set(formData.additionalPayouts.map(p => p.type));
    const allTypes = [
      { value: 'rating_bonus', label: 'Rating Bonus' },
      { value: 'management_fee', label: 'Management Fee' },
      { value: 'other', label: 'Other' }
    ];
    return allTypes.filter(type => !usedTypes.has(type.value));
  };

  // Add this useEffect to fetch rules when cleaner is selected
  useEffect(() => {
    const loadExistingPayoutRules = async () => {
        if (!formData.selectedCleaner) return;
        
        try {
            const existingRules = await fetchPayoutRules(formData.selectedCleaner);
            
            const formattedRules = await Promise.all(existingRules.map(async rule => {
                if (rule.payout_rule_type_id === 'rating_bonus') {
                    const preview = await previewPayoutRule(rule.id);
                    const config = rule.configuration;
                    
                    return {
                        id: rule.id,
                        type: rule.payout_rule_type_id,
                        amount: preview.amount_cents / 100,
                        ratingConfig: {
                            actualRating: config.actual_rating,
                            thresholdRating: config.threshold_rating,
                            bonusPerPoint: config.amount
                        }
                    };
                } else {
                    return {
                        id: rule.id,
                        type: rule.payout_rule_type_id,
                        amount: parseFloat(rule.configuration.amount),
                        description: rule.configuration.description
                    };
                }
            }));

            setFormData(prev => ({
                ...prev,
                additionalPayouts: formattedRules,
                bonusConfig: formattedRules.find(r => r.type === 'rating_bonus')?.ratingConfig
            }));
        } catch (error) {
            console.error('Error loading existing payout rules:', error);
        }
    };

    loadExistingPayoutRules();
  }, [formData.selectedCleaner]);

  // Modify getUniqueProperties to return options in the format React Select expects
  const getPropertyOptions = () => {
    if (!jobs || !formData.selectedCleaner) return [];
    
    const cleanerJobs = jobs.filter(job => 
      job?.cleaner?.id === parseInt(formData.selectedCleaner)
    );
    
    const uniqueProperties = Array.from(new Set(
      cleanerJobs.map(job => job.property?.id)
    )).map(propertyId => {
      const job = cleanerJobs.find(job => job.property?.id === propertyId);
      return {
        value: propertyId.toString(),
        label: job.property?.title
      };
    });
    
    return uniqueProperties;
  };

  // Update handlePropertyChange to trigger filtering
  const handlePropertyChange = (selectedOptions) => {
    const selectedPropertyIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
    
    setFormData(prev => ({
      ...prev,
      selectedProperties: selectedPropertyIds
    }));

    // Immediately filter jobs with the new property selection
    filterJobs(jobs, formData.selectedCleaner, formData.jobStatus, formData.dateRange);
  };

  // Add this effect to set all jobs as selected when jobs are loaded or filtered
  useEffect(() => {
    if (filteredJobs.length > 0) {
      setFormData(prev => ({
        ...prev,
        selectedJobs: filteredJobs.map(job => job.id)
      }));
    }
  }, [filteredJobs]); // This will run whenever filteredJobs changes

  // Get the currency from selected jobs (add this helper function)
  const getSelectedJobsCurrency = () => {
    const selectedJob = filteredJobs.find(job => formData.selectedJobs.includes(job.id));
    return selectedJob?.price_currency || 'EUR';
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Create New Payout</h1>
        <div className={styles.stepIndicator}>
          Step {step} of 2
        </div>
      </div>

      <div className={styles.formContainer}>
        {step === 1 && (
          <div className={styles.step}>
            <h2>Job Selection</h2>
            <div className={styles.formGroup}>
              <label>Select Cleaner</label>
              <select 
                value={formData.selectedCleaner}
                onChange={handleCleanerChange}
                className={styles.select}
              >
                <option value="">Choose a cleaner</option>
                {cleaners.map(cleaner => (
                  <option key={cleaner.id} value={cleaner.id}>
                    {cleaner.first_name} {cleaner.last_name}
                  </option>
                ))}
              </select>
            </div>

            {formData.selectedCleaner && (
              <div className={styles.formGroup}>
                <label>Filter by Properties</label>
                <Select
                  isMulti
                  value={getPropertyOptions().filter(option => 
                    formData.selectedProperties.includes(option.value)
                  )}
                  onChange={handlePropertyChange}
                  options={getPropertyOptions()}
                  className={styles.reactSelect}
                  classNamePrefix="select"
                  placeholder="Select properties..."
                  isClearable={true}
                />
              </div>
            )}

            <div className={styles.formGroup}>
              <label>Date Range</label>
              <div className={styles.dateInputs}>
                <input
                  type="date"
                  name="from"
                  value={formData.dateRange.from}
                  onChange={handleDateRangeChange}
                  className={styles.dateInput}
                />
                <span>to</span>
                <input
                  type="date"
                  name="until"
                  value={formData.dateRange.until}
                  onChange={handleDateRangeChange}
                  className={styles.dateInput}
                />
              </div>
            </div>

            <div className={styles.formGroup}>
              <label>Job Status</label>
              <select
                value={formData.jobStatus}
                onChange={handleJobStatusChange}
                className={styles.select}
              >
                <option value="completed">Completed Only</option>
                <option value="all">All Jobs</option>
              </select>
            </div>

            <div className={styles.jobList}>
              <div className={styles.jobListHeader}>
                <h3>Available Jobs</h3>
                {formData.selectedCleaner ? (
                  <span>{filteredJobs.length} jobs found</span>
                ) : (
                  <span>Please select a cleaner to view available jobs</span>
                )}
              </div>
              
              {formData.selectedCleaner && jobsWithCalculatedData.map(job => (
                <div 
                  key={job.id} 
                  className={`${styles.jobItem} ${job.isSelected ? styles.selected : ''}`}
                  onClick={() => handleJobToggle(job.id)}
                >
                  <div className={styles.jobCheckbox} onClick={(e) => e.stopPropagation()}>
                    <input
                      type="checkbox"
                      checked={job.isSelected}
                      onChange={(e) => handleCheckboxClick(e, job.id)}
                    />
                  </div>
                  <div className={styles.jobDetails}>
                    <h4>{job.property?.title}</h4>
                    <div className={styles.jobInfo}>
                      <span>Date: {job.formattedDate}</span>
                      <span>Status: {job.status}</span>
                      <span>Amount: {job.formattedAmount}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className={styles.buttons}>
              <button onClick={handleBack} className={styles.backButton}>
                Back
              </button>
              <button 
                onClick={handleNext}
                disabled={!formData.selectedCleaner || formData.selectedJobs.length === 0}
                className={styles.nextButton}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className={styles.step}>
            <div className={styles.cleanerHeader}>
              <h2>Review & Payment</h2>
              {cleaners.find(c => c.id === parseInt(formData.selectedCleaner)) && (
                <div className={styles.selectedCleaner}>
                  Payout for: <span>
                    {cleaners.find(c => c.id === parseInt(formData.selectedCleaner)).first_name} {' '}
                    {cleaners.find(c => c.id === parseInt(formData.selectedCleaner)).last_name}
                  </span>
                </div>
              )}
            </div>
            
            {/* Selected Jobs Section */}
            <div className={styles.sectionCard}>
              <div className={styles.sectionTitle}>
                <div className={styles.sectionNumber}>1</div>
                <h3 className={styles.sectionHeading}>Selected Jobs</h3>
              </div>
              <p className={styles.sectionHelp}>Review the jobs included in this payout period</p>
              
              <table className={styles.payoutTable}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Property</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredJobs
                    .filter(job => formData.selectedJobs.includes(job.id))
                    .map(job => (
                      <tr key={job.id}>
                        <td>{new Date(job.date_of_job).toLocaleDateString()}</td>
                        <td>{job.property?.title}</td>
                        <td>{formatCurrency(job.price_cents / 100, job.price_currency)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className={styles.subtotalRow}>
                    <div>Jobs Subtotal</div>
                    <div>
                      {formatCurrency(
                        filteredJobs
                          .filter(job => formData.selectedJobs.includes(job.id))
                          .reduce((sum, job) => sum + job.price_cents / 100, 0),
                        getSelectedJobsCurrency()
                      )}
                    </div>
              </div>
            </div>

            {/* Additional Payouts Section */}
            <div className={styles.sectionCard}>
              <div className={styles.sectionTitle}>
                <div className={styles.sectionNumber}>2</div>
                <h3 className={styles.sectionHeading}>Additional Payouts</h3>
              </div>
              <p className={styles.sectionHelp}>Add bonuses, adjustments, or other additional payments</p>

              {getAvailableTypes().length > 0 && (
                <div className={styles.formGroup}>
                  <select 
                    className={styles.select}
                    value=""
                    onChange={handleAdditionalPayoutTypeChange}
                  >
                    <option value="">Add Payout Type</option>
                    {getAvailableTypes().map(type => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <table className={styles.additionalPayoutsTable}>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Details</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.additionalPayouts.map(payout => (
                    <tr 
                      key={payout.id}
                      className={payout.type === 'rating_bonus' ? styles.ratingBonusRow : ''}
                      onClick={() => payout.type === 'rating_bonus' && handleEditBonus()}
                    >
                      <td>
                        <div className={styles.typeCell}>
                          <button
                            className={styles.deleteButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeletePayout(payout.id);
                            }}
                          >
                            <FiTrash2 size={16} />
                          </button>
                          {payout.type === 'rating_bonus' ? (
                            <div className={styles.ratingBonusType}>
                              Rating Bonus
                              <FiEdit2 className={styles.editIcon} size={14} />
                            </div>
                          ) : payout.type === 'management_fee' ? 'Management Fee' : 'Other'}
                        </div>
                      </td>
                      <td data-label={payout.type === 'other' ? 'Description' : undefined}>
                        {payout.type === 'rating_bonus' ? (
                          <div className={styles.ratingBonusDetails}>
                            {`${getSelectedJobsCurrency() === 'USD' ? '$' : '€'}${payout.ratingConfig.bonusPerPoint}/point > ${payout.ratingConfig.thresholdRating}★ (Current: ${payout.ratingConfig.actualRating}★)`}
                          </div>
                        ) : payout.type === 'other' ? (
                          <input
                            type="text"
                            value={payout.description}
                            onChange={(e) => handlePayoutDescriptionChange(payout.id, e.target.value)}
                            className={styles.input}
                          />
                        ) : null}
                      </td>
                      <td data-label="Amount">
                        {payout.type === 'rating_bonus' ? (
                          formatCurrency(Number(payout.amount), getSelectedJobsCurrency())
                        ) : (
                          <div className={styles.inputGroup}>
                            <span className={styles.currencyIndicator}>
                              {getSelectedJobsCurrency() === 'USD' ? '$' : '€'}
                            </span>
                            <input
                              type="number"
                              value={payout.amount}
                              onChange={(e) => handlePayoutAmountChange(payout.id, e.target.value)}
                              onBlur={() => handlePayoutBlur(payout.id)}
                              className={styles.input}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {formData.additionalPayouts.length > 0 && (
                <div className={`${styles.subtotalRow} ${styles.additionalPayoutsSubtotal}`}>
                  <div>Additional Payouts Subtotal</div>
                  <div>
                    {formatCurrency(
                      formData.additionalPayouts.reduce((sum, payout) => sum + Number(payout.amount), 0),
                      getSelectedJobsCurrency()
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Summary Section */}
            <div className={styles.summaryCard}>
              <h3 className={styles.summaryTitle}>Payout Summary</h3>
              
              <div className={styles.summaryRow}>
                <span className={styles.summaryLabel}>Jobs Subtotal</span>
                <span className={styles.summaryAmount}>
                  {formatCurrency(
                    filteredJobs
                      .filter(job => formData.selectedJobs.includes(job.id))
                      .reduce((sum, job) => sum + job.price_cents / 100, 0),
                    getSelectedJobsCurrency()
                  )}
                </span>
              </div>
              
              <div className={styles.summaryRow}>
                <span className={styles.summaryLabel}>Additional Payouts</span>
                <span className={styles.summaryAmount}>
                  {formatCurrency(
                    formData.additionalPayouts.reduce((sum, payout) => sum + Number(payout.amount), 0),
                    getSelectedJobsCurrency()
                  )}
                </span>
              </div>
              
              <div className={`${styles.summaryRow} ${styles.grandTotal}`}>
                <span className={styles.summaryLabel}>Total Payout Amount</span>
                <span className={styles.summaryAmount}>
                  {formatCurrency(
                    calculateTotalAmount(
                      formData.selectedJobs,
                      filteredJobs,
                      formData.additionalPayouts
                    ) / 100,
                    getSelectedJobsCurrency()
                  )}
                </span>
              </div>
            </div>

            <div className={styles.buttons}>
              <button onClick={handleBack} className={styles.backButton}>
                Back
              </button>
              <button onClick={handleSubmit} className={styles.submitButton}>
                Create Payout
              </button>
            </div>
          </div>
        )}
      </div>

      {showRatingModal && (
        <RatingBonusModal
          isOpen={showRatingModal}
          onClose={() => setShowRatingModal(false)}
          onSave={handleSaveBonus}
          initialValues={formData.bonusConfig}
          currency={getSelectedJobsCurrency()}
        />
      )}
    </div>
  );
}
