import React, { useContext, useState, useEffect, useReducer } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import logo from '../../../assets/images/logonavbar.png';
import { UserContext } from '../../../context/UserContext';
import styles from './Navbar.module.css';
import MobileNavbar from './MobileNavbar';
import { FaChartBar, FaMoneyBill, FaUser, FaSignOutAlt } from 'react-icons/fa';
import { switchUserRole } from '../../../api/usersApi';
export default function Header() {
  const { user, logout, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Set initial mobile state
    setIsMobile(window.innerWidth <= 768);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Update mobile state when user changes
    if (!user) {
      setIsMobile(false);
    } else {
      setIsMobile(window.innerWidth <= 768);
    }
  }, [user]);

  const handleLogout = async () => {
    await logout();
    navigate('/cleaning');
  };

  if (isMobile) {
    return <MobileNavbar />;
  }

  return (
    <header className={styles.header}>
      <nav className={`navbar navbar-expand-sm navbar-light ${styles['navbar-lewagon']}`}>
        <div className="container-fluid">
          <a href="/cleaning" className={`navbar-brand ${styles['navbar-brand']}`}>
            <img src={logo} alt="Le Wagon" width="40" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto">
              {user ? (
                <>
                  <li className={styles['nav-item']}>
                    <NavLink
                      to="/cleaning/calendar"
                      className={({ isActive }) => 
                        `${styles['nav-link']} ${isActive ? styles.active : ''}`
                      }
                    >
                      Calendar
                    </NavLink>
                  </li>
                  <li className={styles['nav-item']}>
                    <NavLink
                      to="/cleaning/properties"
                      className={({ isActive }) => 
                        `${styles['nav-link']} ${isActive ? styles.active : ''}`
                      }
                    >
                      My Properties
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/cleaning/dashboard"
                      className={({ isActive }) => 
                        `dropdown-item ${styles['dropdown-item']} ${isActive ? styles.active : ''}`
                      }
                    >
                      <FaChartBar className="me-2" />
                      Dashboard
                    </NavLink>
                  </li>
                </>
              ) : (
                <li className={styles['nav-item']}>
                  <NavLink 
                    to="/cleaning/login" 
                    className={({ isActive }) => 
                      `nav-link menu__link r-link text-underlined ${styles['nav-link']} ${isActive ? styles.active : ''}`
                    }
                  >
                    Login
                  </NavLink>
                </li>
              )}
            </ul>
            {user && (
              <ul className="navbar-nav ms-auto">
                <li className={`${styles['nav-item']} dropdown ms-3`}>
                  <a 
                    className={`${styles['nav-link']} dropdown-toggle`}
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className={styles.profileButton}>
                      <div className={styles.menuIcon}></div>
                      <div className={styles.profileCircle}>
                        <FaUser />
                      </div>
                    </div>
                  </a>
                  <ul className={`dropdown-menu dropdown-menu-end ${styles['dropdown-menu']}`}>
                    <li>
                      <button 
                        className={`dropdown-item ${styles['dropdown-item']}`}
                        onClick={async () => {
                          try {
                            const response = await switchUserRole(user.current_role);
                            setUser(response.user);
                          } catch (error) {
                            console.error('Error switching role:', error);
                          }
                        }}
                      >
                        <FaUser className={`${styles.icon} me-2`} />
                        Switch to {user.current_role === 'manager' ? 'cleaner' : 'manager'} mode
                      </button>
                    </li>
                    <li>
                      <button 
                        className={`dropdown-item ${styles['dropdown-item']}`}
                        onClick={handleLogout}
                      >
                        <FaSignOutAlt className={`${styles.icon} me-2`} />
                        Logout
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}
