import apiClient from './apiClient';

// Fetch all jobs
export const fetchJobs = async () => {
  try {
    const response = await apiClient.get('/api/jobs');
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

// Fetch a single job by ID
export const fetchJob = async (jobId) => {
  try {
    const response = await apiClient.get(`/api/jobs/${jobId}`);
    return response.data.job;
  } catch (error) {
    console.error(`error fetching job with ID ${jobId}:`, error);
  }
};

// Create a new job
export const createJob = async (jobData) => {
  try {
    const response = await apiClient.post('/api/jobs', jobData);
    return response.data.job;
  } catch (error) {
    console.error('Error creating job:', error);
    throw error;
  }
};

// Delete a job
export const deleteJob = async (jobId) => {
  try {
    await apiClient.delete(`/api/jobs/${jobId}`);
  } catch (error) {
    console.error(`Error deleting job with ID ${jobId}:`, error);
    throw error;
  }
};

// Accept a job
export const acceptJob = async (jobId) => {
  try {
    const response = await apiClient.post(`/api/jobs/${jobId}/apply`);
    return response.data.job;
  } catch (error) {
    console.error(`Error accepting job with ID ${jobId}:`, error);
    throw error;
  }
};

// Reopen a job
export const reopenJob = async (jobId) => {
  try {
    const response = await apiClient.post(`/api/jobs/${jobId}/reopen`);
    return response.data.job;
  } catch (error) {
    console.error(`Error reopening job with ID ${jobId}:`, error);
    throw error;
  }
};

// Update a job
export const updateJob = async (jobId, jobData) => {
  try {
    const response = await apiClient.put(`/api/jobs/${jobId}`, jobData);
    return response.data.job;
  } catch (error) {
    console.error(`Error updating job with ID ${jobId}:`, error);
    throw error;
  }
};