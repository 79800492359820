import React, { useState, useEffect, useContext } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { fetchJobs, deleteJob, acceptJob, reopenJob } from '../../../api/jobsApi';
import styles from './Calendar.module.css';
import JobDetails from './JobDetails';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { FaFilter, FaTrash, FaCalendar, FaList, FaPlus } from 'react-icons/fa'; 
import { UserContext } from '../../../context/UserContext';
import { useNavigate } from 'react-router-dom';

// Set up the localizer for react-big-calendar
const localizer = momentLocalizer(moment);

export default function Calendar() {
  const [jobs, setJobs] = useState([]);
  const [properties, setProperties] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [filters, setFilters] = useState({
    status: '',
    propertyId: 'all',
    excludePastJobs: false,
  });
  const [viewMode, setViewMode] = useState('calendar');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isJobDetailsModalOpen, setIsJobDetailsModalOpen] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [calendarEvents, setCalendarEvents] = useState([]);

  const loadJobs = async () => {
    try {
      const data = await fetchJobs();
      setJobs(data.jobs);
      setProperties(data.properties);
      setFilteredJobs(data.jobs);
      setUserRole(data.role); // Set the user role from the backend response
      setCalendarEvents(formatEventsForCalendar(data.jobs)); // Add this line
    } catch (error) {
      console.error('Failed to fetch jobs', error);
    }
  };

  useEffect(() => {
    loadJobs();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filters, jobs, userRole]);

  const applyFilters = () => {
    let result = jobs;

    if (filters.status) {
      result = filterByStatus(result, filters.status);
    }

    if (filters.propertyId && filters.propertyId !== 'all') {
      result = filterByProperty(result, filters.propertyId);
    }

    if (filters.excludePastJobs) {
      result = filterOutPastJobs(result);
    }

    setFilteredJobs(result);
    setCalendarEvents(formatEventsForCalendar(result)); // Add this line
  };

  const toggleFilterModal = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  const filterByStatus = (jobsToFilter, status) => {
    switch (status) {
      case 'open':
        return userRole === 'manager'
          ? jobsToFilter.filter(job => job.status === 'open')
          : jobsToFilter.filter(job => job.status === 'open' && !job.job_applications.some(appl => appl.user_id === user.id));
      case 'applied':
        return userRole === 'manager'
          ? jobsToFilter.filter(job => job.status === 'applied')
          : jobsToFilter.filter(job => job.status === 'applied' && job.job_applications.some(appl => appl.user_id === user.id));
      case 'accepted':
        return jobsToFilter.filter(job => job.status === 'accepted');
      case 'completed':
        return jobsToFilter.filter(job => job.status === 'completed');
      default:
        return jobsToFilter;
    }
  };

  const filterByProperty = (jobsToFilter, propertyId) => {
    return jobsToFilter.filter(job => job.property_id === parseInt(propertyId));
  };

  const filterOutPastJobs = (jobsToFilter) => {
    const now = new Date();
    return jobsToFilter.filter(job => new Date(job.date_of_job) >= now);
  };

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  const handleJobClick = (job) => {
    setSelectedJob(job.id);
    setIsJobDetailsModalOpen(true);
  };

  const closeJobDetailsModal = (e) => {
    if (!e || e.target.classList.contains(styles.fullPageModalOverlay)) {
      setIsJobDetailsModalOpen(false);
      setSelectedJob(null);
    }
  };

  const formatEventsForCalendar = (jobs) => {
    return jobs.map(job => ({
      id: job.id,
      title: job.property.title,
      start: new Date(`${job.date_of_job}T${job.cleaning_from}`),
      end: new Date(`${job.date_of_job}T${job.cleaning_until}`),
      status: job.status, 
      price: `${job.price_cents / 100} ${job.price_currency}`,
      job: job,
    }));
  };

  const eventStyleGetter = (event) => {
    const statusClass = styles[event.status] || styles.default;
    return {
      className: `${styles.event} ${statusClass}`
    };
  };

  const handleDeleteJob = async (jobId, event) => {
    event.stopPropagation();
    if (window.confirm('Are you sure you want to delete this job?')) {
      try {
        await deleteJob(jobId);
        handleJobDeleted(jobId);
      } catch (error) {
        console.error('Error deleting job:', error);
      }
    }
  };

  const handleJobDeleted = (deletedJobId) => {
    setJobs(prevJobs => prevJobs.filter(job => job.id !== deletedJobId));
    setFilteredJobs(prevFilteredJobs => prevFilteredJobs.filter(job => job.id !== deletedJobId));
    setCalendarEvents(prevEvents => prevEvents.filter(event => event.id !== deletedJobId));
  };

  const handleAcceptJob = async (jobId, event) => {
    event.stopPropagation();
    try {
      const updatedJob = await acceptJob(jobId);
      
      // Update jobs, filteredJobs, and calendarEvents
      const updateJob = (job) => job.id === jobId ? { ...job, status: updatedJob.status } : job;
      
      setJobs(prevJobs => prevJobs.map(updateJob));
      setFilteredJobs(prevFilteredJobs => prevFilteredJobs.map(updateJob));
      setCalendarEvents(prevEvents => prevEvents.map(event => 
        event.id === jobId ? { ...event, status: updatedJob.status } : event
      ));

      // The modal will close automatically as it's already handled in JobDetails
    } catch (error) {
      console.error('Error accepting job:', error);
      throw error;
    }
  };

  const handleReopenJob = async (jobId, event) => {
    event.stopPropagation();
    try {
      const reopenedJob = await reopenJob(jobId);

      // Update jobs, filteredJobs, and calendarEvents
      const updateJob = (job) => job.id === jobId ? { ...job, status: reopenedJob.status } : job;
      
      setJobs(prevJobs => prevJobs.map(updateJob));
      setFilteredJobs(prevFilteredJobs => prevFilteredJobs.map(updateJob));
      setCalendarEvents(prevEvents => prevEvents.map(event => 
        event.id === jobId ? { ...event, status: reopenedJob.status } : event
      ));
    } catch (error) {
      console.error('Error reopening job:', error);
      throw error;
    }
  };

  return (
    <div className={styles.calendarContainer}> 
      <div className={styles.controlsContainer}>
        <div className={styles.viewToggle}>
          <button
            onClick={() => handleViewModeChange('calendar')}
            className={`${styles.viewButton} ${viewMode === 'calendar' ? styles.activeView : ''}`}
          >
            <FaCalendar /> <span className={styles.buttonText}>Calendar View</span>
          </button>
          <button
            onClick={() => handleViewModeChange('list')}
            className={`${styles.viewButton} ${viewMode === 'list' ? styles.activeView : ''}`}
          >
            <FaList /> <span className={styles.buttonText}>List View</span>
          </button>
        </div>
        <div className={styles.rightControls}>
          <button onClick={toggleFilterModal} className={styles.filterButton}>
            <FaFilter />
          </button>
          {userRole === 'manager' && (
            <button onClick={() => navigate('/cleaning/jobs/new')} className={styles.addJobButton}>
              <FaPlus /> <span className={styles.buttonText}>Add Job</span>
            </button>
          )}
        </div>
      </div>

      {isFilterModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <h2>Filters</h2>
            <div className={styles.filtersContainer}>
              <div className={styles.filterItem}>
                <label htmlFor="status">Status:</label>
                <select id="status" name="status" value={filters.status} onChange={handleFilterChange}>
                  <option value="">All Statuses</option>
                  <option value="open">Open</option>
                  <option value="applied">Applied</option>
                  <option value="accepted">Accepted</option>
                  <option value="completed">Completed</option>
                </select>
              </div>

              <div className={styles.filterItem}>
                <label htmlFor="propertyId">Property:</label>
                <select id="propertyId" name="propertyId" value={filters.propertyId} onChange={handleFilterChange}>
                  <option value="all">All Properties</option>
                  {properties.map(property => (
                    <option key={property.id} value={property.id}>{property.title}</option>
                  ))}
                </select>
              </div>

              <div className={styles.filterItem}>
                <label className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    name="excludePastJobs"
                    checked={filters.excludePastJobs}
                    onChange={handleFilterChange}
                  />
                  Exclude Past Jobs
                </label>
              </div>
            </div>
            <button onClick={toggleFilterModal} className={styles.closeButton}>Close</button>
          </div>
        </div>
      )}

      {isJobDetailsModalOpen && selectedJob && (
        <div className={styles.fullPageModalOverlay} onClick={closeJobDetailsModal}>
          <div className={styles.fullPageModalContent}>
            <JobDetails 
              jobId={selectedJob} 
              onClose={() => closeJobDetailsModal()} 
              user={user} 
              onJobDeleted={handleJobDeleted}
              handleAcceptJob={handleAcceptJob}
              handleReopenJob={handleReopenJob}
            />
          </div>
        </div>
      )}

      {viewMode === 'calendar' ? (
        <div className={styles.calendarView}>
          <BigCalendar
            localizer={localizer}
            events={calendarEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            eventPropGetter={eventStyleGetter}
            className={styles.calendarView}
            onSelectEvent={(event) => handleJobClick(event.job)}
          />
        </div>
      ) : (
        <div className={styles.jobList}>
          {filteredJobs.map((job) => (
            <div 
              key={job.id} 
              className={styles.jobItem}
              onClick={() => handleJobClick(job)}
            >
              <div className={styles.jobItemHeader}>
                <h3>{job.property.title}</h3>
                {userRole === 'manager' && (
                  <button 
                    onClick={(e) => handleDeleteJob(job.id, e)} 
                    className={styles.deleteButton} 
                    title="Delete Job"
                  >
                    <FaTrash />
                  </button>
                )}
              </div>
              <p>Date: {new Date(job.date_of_job).toLocaleDateString()}</p>
              <p>Time: {job.cleaning_from} - {job.cleaning_until}</p>
              <p>Status: {job.status}</p>
              <p>Price: {job.price_cents / 100} {job.price_currency}</p>
              {job.status === 'open' && userRole !== 'manager' && (
                <button 
                  onClick={(e) => handleAcceptJob(job.id, e)}
                  className={styles.acceptButton}
                >
                  Accept Job
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
