import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { UserContext } from '../../../context/UserContext';
import styles from './MobileNavbar.module.css';
import { 
  FaCalendarAlt, 
  FaHome, 
  FaChartBar, 
  FaBars, 
  FaSignOutAlt,
  FaUser
} from 'react-icons/fa';
import { switchUserRole } from '../../../api/usersApi';

// Check if Netlify deploy is working.
export default function MobileNavbar() {
  const { user, logout, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [showFinancialMenu, setShowFinancialMenu] = useState(false);
  const menuRef = useRef(null);
  const financialMenuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMenu && menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
      if (showFinancialMenu && financialMenuRef.current && !financialMenuRef.current.contains(event.target)) {
        setShowFinancialMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu, showFinancialMenu]);

  const handleLogout = async () => {
    await logout();
    navigate('/cleaning');
    setShowMenu(false);
  };

  const toggleMenu = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
    setShowFinancialMenu(false);
  };

  const toggleFinancialMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowFinancialMenu(!showFinancialMenu);
    setShowMenu(false);
  };

  return (
    <div className={styles.mobileNavbarWrapper}>
      <nav className={styles.mobileNavbar}>
        <NavLink to="/cleaning/calendar" className={styles.navItem}>
          <FaCalendarAlt className={styles.icon} />
          <span>Calendar</span>
        </NavLink>
        <NavLink to="/cleaning/properties" className={styles.navItem}>
          <FaHome className={styles.icon} />
          <span>Properties</span>
        </NavLink>
        {user && (
          <NavLink 
            to="/cleaning/dashboard" 
            className={styles.navItem} 
            onClick={() => setShowFinancialMenu(false)}
          >
          <FaChartBar className={styles.icon} />
          <span>Dashboard</span>
        </NavLink>
        )}
        <button onClick={toggleMenu} className={styles.navItem}>
          <FaBars className={styles.icon} />
          <span>Menu</span>
        </button>
      </nav>

      {showMenu && (
        <div ref={menuRef} className={styles.menu}>
          <button 
            className={styles.menuItem}
            onClick={async () => {
              try {
                const response = await switchUserRole(user.current_role);
                setUser(response.user);
                setShowMenu(false);
              } catch (error) {
                console.error('Error switching role:', error);
              }
            }}
          >
            <FaUser className={styles.icon} />
            <span>Switch to {user.current_role === 'manager' ? 'cleaner' : 'manager'} mode</span>
          </button>
          <button onClick={handleLogout} className={styles.menuItem}>
            <FaSignOutAlt className={styles.icon} />
            <span>Logout</span>
          </button>
        </div>
      )}
    </div>
  );
}
