import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchJob, deleteJob } from 'api/jobsApi';
import { UserContext } from 'context/UserContext';
import styles from './JobDetails.module.css';
import { FaTrash, FaEdit } from 'react-icons/fa';
import MapComponent from 'cleaningApp/components/MapBox/MapComponent';
import { MapPinIcon } from '@heroicons/react/24/outline';
const JobDetails = ({ jobId, onClose, onJobDeleted, user, handleAcceptJob, handleReopenJob }) => {
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user: userContext } = useContext(UserContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    fetchJob(jobId).then(setJob).catch(console.error).finally(() => setLoading(false));
  }, [jobId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!job) return <div>No job found</div>;
  console.log(job);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const formatTime = (timeString) => {
    return timeString ? timeString.slice(0, 5) : 'N/A'; // Add null check
  };

  const handlePropertyClick = () => {
    navigate(`/cleaning/properties/${job.property.id}`);
  };

  const handleDeleteJob = async () => {
    if (window.confirm('Are you sure you want to delete this job?')) {
      try {
        await deleteJob(jobId);
        onClose(); // Close the modal after successful deletion
        onJobDeleted(jobId); // Notify the parent component that a job was deleted
      } catch (error) {
        console.error('Error deleting job:', error);
        if (error.response && error.response.data && error.response.data.error) {
          setErrorMessage(error.response.data.error); // Set error message from response
        } else {
          setErrorMessage('An unexpected error occurred while deleting the job.'); // Fallback error message
        }
      }
    }
  };

  const handleEditJob = async () => {
    navigate(`/cleaning/jobs/${jobId}/edit`);
  };

  const handleAcceptJobClick = async (event) => {
    event.stopPropagation();
    try {
      await handleAcceptJob(jobId, event);
      onClose(); // Close the modal after accepting the job
    } catch (error) {
      console.error('Error accepting job:', error);
      setError('Failed to accept job');
    }
  };

  const handleReopenJobClick = async (event) => {
    event.stopPropagation();
    try {
      await handleReopenJob(jobId, event);
      console.log('Job reopened successfully, closing modal.');
      onClose(); // Only close on success
    } catch (error) {
      console.error('Error reopening job:', error);
      if (error.response && error.response.status === 422) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('An unexpected error occurred.');
      }
      console.log('Error occurred, modal should remain open.');
    }
  };

  const closeErrorPopup = () => {
    setErrorMessage(null);
  };

  return (
    <div className={styles.jobDetailsContainer}>
      {errorMessage && (
        <div className={styles.errorPopupOverlay} onClick={closeErrorPopup}>
          <div className={styles.errorPopup}>
            <p>{errorMessage}</p>
            <button onClick={closeErrorPopup} className={styles.closeErrorButton}>Close</button>
          </div>
        </div>
      )}
      <div className={styles.jobHeader}>
        <h1 className={styles.jobTitle} onClick={handlePropertyClick} style={{ cursor: 'pointer' }}>
          {job.property.title}
        </h1>
        {userContext.current_role === 'manager' && (
          <>
          <button onClick={handleDeleteJob} className={styles.deleteButton} title="Delete Job">
            <FaTrash />
          </button>
          <button onClick={handleEditJob} className={styles.editButton} title="Edit Job">
              <FaEdit />
          </button>
          </>
        )}
      </div>
      
      <div className={styles.section}>
        <h2>Job Details</h2>
        <p><strong>Status:</strong> {job.status}</p>
        <p><strong>Date:</strong> {formatDate(job.date_of_job)}</p>
        <p><strong>Cleaning Time:</strong> {formatTime(job.cleaning_from)} - {formatTime(job.cleaning_until)}</p>
        {job.description && <p><strong>Description:</strong> {job.description}</p>}
      </div>

      {userContext.current_role === 'manager' ? (
        <>
          <div className={styles.section}>
            <h2>Financial Information</h2>
            <p><strong>Price:</strong> {job.price_cents / 100} {job.price_currency}</p>
          </div>

          

          {job.selected_cleaner && (
            <div className={styles.section}>
              <h2>Selected Cleaner</h2>
              <p><strong>Name:</strong> {job.selected_cleaner.first_name} {job.selected_cleaner.last_name}</p>
              <p><strong>Email:</strong> {job.selected_cleaner.email}</p>
            </div>
          )}

          {!job.selected_cleaner && job.cleaners && job.cleaners.length > 0 && (
            <div className={styles.section}>
              <h2>Cleaners Eligible for this Job</h2>
              <ul className={styles.cleanersList}>
                {job.cleaners.map(cleaner => (
                  <li key={cleaner.id} className={styles.cleanerItem}>
                    <span className={styles.cleanerName}>{cleaner.first_name}</span>
                    <span className={styles.cleanerEmail}>({cleaner.email})</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      ) : (
        <>
          <div className={styles.section}>
            <h2>Financial Information</h2>
            <p><strong>Revenue:</strong> {job.revenue_cents / 100} {job.revenue_currency}</p>
          </div>

          <div className={styles.section}>
            <h2>Property Information</h2>
            <p><strong>Square Meters:</strong> {job.property.square_meters}</p>
            <p><strong>Number of Rooms:</strong> {job.property.number_rooms}</p>
            <p><strong>Number of Bathrooms:</strong> {job.property.number_bathrooms}</p>
          </div>

          <div className={styles.section}>
            <h2>Property Address</h2>
            <section className={styles.locationSection}>
            <div className={styles.address}>
              <p>{job.property.address}</p>
            </div>
            <div className={styles.mapContainer}>
              <MapComponent 
                address={job.property.address}
                latitude={job.property.latitude}
                longitude={job.property.longitude}
                onAddressChange={() => {}}
              />
            </div>
          </section>
          </div>

          <div className={styles.section}>
            <h2>Manager Information</h2>
            <p><strong>Name:</strong> {job.manager.first_name} {job.manager.last_name}</p>
            <p><strong>Email:</strong> {job.manager.email}</p>
          </div>
        </>
      )}

      <div className={styles.buttonContainer}>
        {user.current_role !== 'manager' && job.status === 'open' && (
          <button onClick={handleAcceptJobClick} className={styles.acceptButton}>Accept Job</button>
        )}
        {user.current_role === 'manager' && (job.status === 'accepted' || job.status === 'completed') && (
          <button onClick={handleReopenJobClick} className={styles.reopenButton}>Reopen Job</button>
        )}
        <button onClick={onClose} className={styles.closeButton}>Close</button>
      </div>
    </div>
  );
};

export default JobDetails;
