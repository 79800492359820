import React from "react"
import DateNavigation from './components/DateNavigation/DateNavigation';
import JobItem from './components/JobItem/JobItem';
import Payouts from './components/Payouts/Payouts';
import SummaryStats from './components/SummaryStats/SummaryStats';
import { getPayouts } from '../../../api/payoutsApi';
import { getStartOfMonth, getEndOfMonth } from './utils/dateUtils';
import { fetchJobs } from '../../../api/jobsApi';
import styles from "./Dashboard.module.css"

export default function Dashboard() {

  const [jobs, setJobs] = React.useState([]);
  const [userRole, setUserRole] = React.useState('');
  const [payoutsData, setPayoutsData] = React.useState({ payouts: [], statistics: {} });

  // + 'Z' is added to the end of the string to make sure it is UTC time
  const [from, setFrom] = React.useState(getStartOfMonth(new Date()));
  const [until, setUntil] = React.useState(getEndOfMonth(new Date()));

  // Function to filter jobs based on the date range
  const filterJobsByDate = (jobs, fromDate, untilDate) => {
    console.log(jobs);
    if (!jobs) return [];
    return jobs.filter(job => {
      const jobDate = new Date(job.date_of_job);
      return jobDate >= fromDate && jobDate <= untilDate;
    });
  };

  // Fetch both jobs and payouts data
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch jobs
        const jobsData = await fetchJobs();
        console.log("The jobs data is", jobsData);
        setJobs(jobsData.jobs || []);
        setUserRole(jobsData.role || '');

        // Fetch payouts
        const payoutsResponse = await getPayouts(from, until);
        setPayoutsData(payoutsResponse);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchData();
  }, [from, until]);

  // Filtered jobs for the currently selected date range
  const dateFilteredJobs = filterJobsByDate(jobs, from, until);

  const [fieldFilteredJobs, setFieldFilteredJobs] = React.useState(dateFilteredJobs);

  React.useEffect(() => {
  }, [fieldFilteredJobs]);

  const handleJobDeleted = (deletedJobId) => {
    setJobs(prevJobs => prevJobs.filter(job => job.id !== deletedJobId));
  };

  return (

    <div>
      <DateNavigation from={from} setFrom={setFrom} until={until} setUntil={setUntil} />
      <Payouts from={from} until={until} payoutsData={payoutsData} />
      <SummaryStats 
        jobs={dateFilteredJobs} 
        payoutsData={payoutsData} 
        userRole={userRole} 
        setJobs={setJobs}
      />
      {/* <JobList jobs={dateFilteredJobs} userRole={userRole} fieldFilteredJobs={fieldFilteredJobs} setFieldFilteredJobs={setFieldFilteredJobs} from={from} until={until} onJobDeleted={handleJobDeleted} payoutsData={payoutsData} /> */}
      <JobItem />
    </div>
  )
}
